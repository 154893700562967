import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const CHARGING_STATION_LOCALES = {
	WOLTAIR_CZ: 'nabijeci-stanice',
	WOLTAIR_DE: null,
	WOLTAIR_IT: null,
	WOLTAIR_PL: null
}

export const CHARGING_STATION = CHARGING_STATION_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return CHARGING_STATION === param
}) satisfies ParamMatcher
