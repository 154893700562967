import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const COOPERATION_LOCALES = {
	WOLTAIR_CZ: 'spoluprace',
	WOLTAIR_PL: 'wspolpraca',
	WOLTAIR_DE: 'zusammenarbeit',
	WOLTAIR_IT: 'diventa-partner'
}

export const COOPERATION = COOPERATION_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return COOPERATION === param
}) satisfies ParamMatcher
